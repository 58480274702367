import React from 'react'

import {useAppCtx} from '../../../../contexts/appCtx'
import HomeDesktopLayout from './HomeDesktopLayout'
import HomeMobileLayout from './HomeMobileLayout'

export default function HomeLayout(props) {
  const appCtx = useAppCtx()
  return (
    <>
      {/* Desktop Mode */}
      {!appCtx.isMobile && (
        <HomeDesktopLayout {...props}/>
      )}
      {/* Mobile Mode */}
      {appCtx.isMobile && (
        <HomeMobileLayout {...props}/>
      )}
    </>
  )
}
