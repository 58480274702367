import React from 'react'
import {useRouter} from 'next/router'
import {setCookie} from 'cookies-next'
import {useTranslations} from 'next-intl'

import Select from '../../elements/Select'
import useLocales from '../../../hooks/useLocales'


export default function LocaleSelect({...props}) {
  const t = useTranslations()
  const router = useRouter()

  const locales = useLocales()

  const localeSelections = React.useMemo(() => locales
    .map(locale => ({
      key: locale,
      label: t(`locales.${locale}`),
      value: locale,
    })), [locales, t])

  const onLocaleChange = React.useCallback((value) => {
    (async function () {
      // 若手動切換語言就記著使用者的選擇
      setCookie('NEXT_LOCALE', value, {sameSite: 'strict'})
      await router.replace(router.asPath, router.asPath, {locale: value})
    })()
  }, [router])

  React.useEffect(() => {
    if (locales.length > 0 && locales.indexOf(router.locale) === -1) {
      onLocaleChange(locales[0])
    }
  }, [router.locale, onLocaleChange, locales])

  return (
    <Select
      currentValue={router.locale}
      selections={localeSelections}
      onSelectionChange={onLocaleChange}
      {...props}
    />
  )
}

