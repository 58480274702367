import React from 'react'
import dynamic from 'next/dynamic'
import {useTranslations} from 'next-intl'
import {IconButton, Table, TableCell, TableRow} from '@mui/material'
import {css, useTheme} from '@emotion/react'
import SettingsIcon from '@mui/icons-material/Settings'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

import LocaleSelect from '../LocaleSelect'
import DarkModeToggle from '../DarkModeToggle'
import BasicDialog from '../../elements/BasicDialog'
import Paper from '../../elements/Paper'
import {useDeveloperMode} from '../../../contexts/developerMode'
// import LoginControl from './LoginControl'


const Tour = dynamic(() => import('./Tour'))


function SettingsControl({icon, tourSteps, ...props}) {
  const theme = useTheme()
  const styles = {
    root: css`
    `,

    desktopContent: css`
      display: flex;
      gap: 8px;
      align-items: center;
    `,

    mobileContent: css`

    `,

    dialogContent: css`
      background: ${theme.palette.primary.lightest};
      color: ${theme.palette.onSurface.dark};

      padding: 8px;
      height: 100%;
    `,

    label: css`
      font-size: 1.2rem;
      font-weight: 600;
    `,

    tour: css`
      @media (max-width: ${theme.breakpoints.sm}px) {
        display: none;
      }
    `
  }

  const t = useTranslations('settings-control')

  const [controlDialogOpen, setShareDialog] = React.useState(false)
  const onControlButtonClick = () => {
    setShareDialog(true)
  }

  const onCloseControlDialog = () => {
    setShareDialog(false)
  }

  const [tourRunning, onTourRunningChange] = React.useState(false)
  const developMode = useDeveloperMode()

  return (
    <>
      <div css={styles.root} {...props}>
        {/* Normal Mode */}
        {!icon && (
          <div css={styles.desktopContent}>
            {developMode && <DarkModeToggle aria-label={t('appearance')}/>}
            <LocaleSelect aria-label={t('language')}/>
            {tourSteps && (
              <IconButton
                aria-label={t('tour')} css={styles.tour}
                onClick={() => onTourRunningChange(runTour => !runTour)}>
                <QuestionMarkIcon/>
              </IconButton>
            )}
            {tourRunning && <Tour steps={tourSteps} running={tourRunning} onChange={onTourRunningChange}/>}
            {/*{<LoginControl/>}*/}
          </div>
        )}
        {/* Icon Mode */}
        {icon && (
          <div css={styles.mobileContent}>
            <IconButton aria-label={t('title')} onClick={onControlButtonClick}>
              <SettingsIcon/>
            </IconButton>
          </div>
        )}
      </div>

      <BasicDialog
        title={t('title')}
        open={controlDialogOpen}
        onClose={onCloseControlDialog}
      >
        <Paper css={styles.dialogContent}>
          <Table size="small">
            {developMode && <TableRow>
              <TableCell css={styles.label}>{t('appearance')}</TableCell>
              <TableCell align="right"><DarkModeToggle aria-label={t('appearance')}/></TableCell>
            </TableRow>}
            <TableRow>
              <TableCell css={styles.label}>{t('language')}</TableCell>
              <TableCell align="right"><LocaleSelect aria-label={t('language')}/></TableCell>
            </TableRow>
          </Table>
        </Paper>
      </BasicDialog>
    </>
  )
}

export default React.memo(SettingsControl)
