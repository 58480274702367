import React from 'react'
import {useTranslations} from 'next-intl'
import {css, useTheme} from '@emotion/react'

import SettingsControl from '../../../../components/modules/SettingsControl'

export default function HomeDesktopLayout({children, ...props}) {
  const theme = useTheme()
  const styles = {
    header: css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 8px;
    `,
    title: css`
      color: ${theme.palette.onPrimary.dark};
      font-size: 2.0em;
      font-weight: 700;
      margin: unset;
    `,
    relatedTools: css`
      @media (max-width: ${theme.breakpoints.md}px) {
        display: none;
      }
    `,
    description: css`
      margin-bottom: 16px;
      font-size: 1.2rem;
    `,
    content: css`
      flex: 1;
      position: relative;
    `,
  }

  const t = useTranslations()

  return (
    <>
      <header id="header">
        <div css={styles.header}>
          <div css={css`
            display: flex;
            align-items: flex-end;
            gap: 16px;
          `}>
            <h1 id="title" css={styles.title}>{t('home.title')}</h1>
          </div>

          {/* 設定不需要爬蟲抓取 */}
          <div css={styles.settings} data-nosnippet={true}>
            <SettingsControl/>
          </div>
        </div>
        <div css={styles.description}>{t('home.description')}</div>
      </header>
      {/* 內容不需要爬蟲抓取 */}
      <div css={styles.content} data-nosnippet={true}>
        {children}
      </div>
    </>
  )
}
